<div class="content-container m-0">
    <div class="d-flex my-4">
        <div class="login-form mx-auto">
            <form (ngSubmit)="doForgotPassword()" #forgotPasswordForm="ngForm">
                <div class="d-flex mb-3 flex-column">
                    <img class="logo p-4" src="/assets/logo-large.png" alt="App Logo" />
                </div>

                <div class="alert alert-danger text-break" role="alert" *ngIf="error">Error: {{ error }}</div>
                <div class="alert alert-info text-break" role="alert" *ngIf="success">
                    An email will be sent to this address if an account is registered under it.
                </div>

                <div class="form-group">
                    <label class="form-label" for="forgotten_email">Email</label>
                    <input class="form-control" type="email" id="forgotten_email" name="email" required email [(ngModel)]="email" autocomplete="username" />
                </div>

                <div class="d-flex">
                    <input type="submit" value="Reset Password" class="btn btn-primary ms-0 mb-4" [disabled]="!forgotPasswordForm.form.valid || loading" />
                </div>

                <p class="my-0 small"><a routerLink="/login"><i class="fa fa-arrow-left text-primary"></i> Back to login</a></p>
            </form>
        </div>
    </div>
</div>
