export const environment = {
    production: true,
    env: 'staging',
    apiUrl: 'https://api.staging.kdss.h6.run',
    whitelistedDomains: ['api.staging.kdss.h6.run'],
    blacklistedRoutes: [],
    recaptcha_site_key: '6LcFDeYUAAAAACnO-36JVGlpgofBw3B7287rtdDL',
    // Per Stripe documentation
    // https://stripe.com/docs/keys
    // This is OK to publish
    stripePublishableKey: 'pk_test_xePpOhN0gQErKDgzMeHPuRjB',
};
