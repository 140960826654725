<ng-container *ngIf="section$ | async as section">
    <h2>{{ section.data.label }}</h2>

    <ng-container *ngIf="section.data.juniorOnly">
        <p>Juniors Only</p>
    </ng-container>

    <ng-container *ngIf="section.data.membersOnly">
        <p>Associates & Members Only</p>
    </ng-container>

    <ng-container *ngIf="section.data.fee || section.data.feeMember">
        <div>
            <strong>Fees</strong><br />
            <ng-container *ngIf="section.data.feeMember">
                <div>
                    <span class="comma">Associates & Members: ${{ section.data.feeMember }}</span
                    ><br />
                </div>
            </ng-container>
            <ng-container *ngIf="section.data.feeMember">
                <div>
                    <span class="comma">Non Associates & Non Members: ${{ section.data.fee }}</span>
                </div>
            </ng-container>
            <small><strong>unless noted otherwise</strong></small>
            <br />
        </div>
    </ng-container>

    <ng-container *ngIf="section.data.closingDateOnline">
        <p>
            <strong>Entries Close:</strong>
            {{ section.data.closingDateOnline | date: 'fullDate' }}
        </p>
    </ng-container>

    <ng-container *ngIf="section.data.deliveryDate">
        <p>
            <strong>Delivery:</strong>
            {{ section.data.deliveryDate | date: 'fullDate' }}.
            {{ section.data.deliveryNote }}
        </p>
    </ng-container>

    <ng-container *ngIf="section.data.collectionDate">
        <p>
            <strong>Collection:</strong> {{ section.data.collectionDate | date: 'fullDate' }}.
            {{ section.data.collectionNote }}
        </p>
    </ng-container>

    <ng-container *ngIf="section.data.prizeSet">
        <p>
            <strong>Sponsors & Prizes</strong><br />
            <ng-container *ngIf="section.data.prizeSet.name?.length"> {{ section.data.prizeSet.name }}:<br /> </ng-container>
            <ng-container *ngIf="section.data.prizeSet.notes?.length">
                {{ section.data.prizeSet.notes }}
            </ng-container>
        </p>
    </ng-container>

    <h2>Classes</h2>

    <div class="row top section.data-list">
        <div class="small-12 columns">
            <ng-container *ngFor="let division of section.data.divisions">
                <h3 class="division-header">{{ division.string }}</h3>
                <div class="division-desc">{{ division.notes }}</div>
                <ng-container *ngFor="let divisionClass of division.classes">
                    <div class="small-4 columns">
                        <h5>{{ divisionClass.string }}</h5>
                    </div>
                    <div *ngIf="divisionClass.instructions?.length" class="small-4 columns"><strong>Instructions: </strong></div>
                    <div *ngIf="divisionClass.instructions?.length" class="small-4 columns">{{ divisionClass.instructions }}&nbsp;</div>
                    <div *ngIf="divisionClass.groupPrizeSet || divisionClass.prizeSet" class="small-4 columns"><strong>Sponsors & Prizes: </strong></div>
                    <ng-container *ngIf="divisionClass.groupPrizeSet">
                        <ng-container *ngIf="divisionClass.groupPrizeSet.name?.length"> {{ divisionClass.groupPrizeSet.name }}:<br /></ng-container>
                        <ng-container *ngIf="divisionClass.groupPrizeSet.notes?.length">
                            {{ divisionClass.groupPrizeSet.notes }}
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="divisionClass.prizeSet">
                        <ng-container *ngIf="divisionClass.prizeSet.name?.length"> {{ divisionClass.prizeSet.name }}:<br /> </ng-container>
                        <ng-container *ngIf="divisionClass.prizeSet.notes?.length">
                            {{ divisionClass.prizeSet.notes }}
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>
