import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiErrorResponse, ApiResponse, ApiServiceInterface, UserServiceInterface } from '@hutsix/ngxh6';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-forget-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    @ViewChild('forgotPasswordForm') forgotPasswordForm: NgForm;

    private unsubscribe$ = new Subject<void>();

    public email: string;
    public redirect?: string;
    public error?: string;
    public success?: boolean = false;
    public loading = false;

    constructor(
        @Inject('ApiService') private api: ApiServiceInterface,
        @Inject('UserService') private userService: UserServiceInterface,
        private cdRef: ChangeDetectorRef,
        private route: ActivatedRoute,
    ) {}

    public doForgotPassword(): void {
        this.loading = true;
        this.api.post({ url: '/api/user/forgotten_password', data: { email: this.email }, skipAuth: true }).subscribe(
            (res: ApiResponse<any>) => {
                this.forgotPasswordForm.reset();
                this.error = null;
                this.loading = false;
                this.success = true;
            },
            (err: ApiErrorResponse<any>) => {
                this.loading = false;
                this.error = err.error.message;
                this.cdRef.detectChanges();
            },
        );
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => (this.redirect = params.returnUrl || null));

        this.userService.watch.pipe(takeUntil(this.unsubscribe$)).subscribe(user => {
            if (user.loggedIn) this.userService.reloadRoute(this.redirect || '/');
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
