<div class="content-container m-0">
    <div class="d-flex my-4">
        <div class="login-form mx-auto">
            <form [formGroup]="resetPasswordForm" (ngSubmit)="doResetPassword()">
                <div class="d-flex mb-3 flex-column">
                    <img class="logo p-4" src="/assets/logo-large.png" alt="App Logo" />
                </div>

                <div class="alert alert-danger text-break" role="alert" *ngIf="error">Error: {{ error }}</div>
                <div class="alert alert-info text-break" role="alert" *ngIf="success">Your password has been successfully updated</div>
                <div *ngIf="!token || invalidToken" class="alert alert-danger">Invalid token</div>

                <ng-container *ngIf="token && !success">
                    <div class="form-group">
                        <label class="form-label" for="register_password">New Password</label>
                        <input class="form-control" type="password" id="register_password" name="password" formControlName="password" />
                        <div
                            *ngIf="
                                resetPasswordForm.get('password').invalid &&
                                (resetPasswordForm.get('password').dirty || resetPasswordForm.get('password').touched)
                            "
                            class="col-12"
                        >
                            <small *ngIf="resetPasswordForm.get('password').errors.required" class="error-text text-danger">
                                <span>This value should not be blank.<br /></span>
                            </small>
                            <small
                                *ngIf="resetPasswordForm.get('password').errors.minlength && resetPasswordForm.get('password').touched"
                                class="error-text text-danger"
                            >
                                <span>Your password should be at least 10 characters<br /></span>
                            </small>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-label" for="register_repeat_password">Repeat Password</label>
                        <input class="form-control" type="password" id="register_repeat_password" name="repeatPassword" formControlName="repeatPassword" />
                        <div *ngIf="resetPasswordForm.get('repeatPassword').invalid && resetPasswordForm.get('repeatPassword').touched" class="col-12 col-sm-8">
                            <small *ngIf="resetPasswordForm.get('repeatPassword').errors.required" class="error-text text-danger">
                                <span>This value should not be blank.<br /></span>
                            </small>
                            <small
                                *ngIf="resetPasswordForm.get('repeatPassword').errors.minLength && resetPasswordForm.get('repeatPassword').touched"
                                class="error-text text-danger"
                            >
                                <span>Your password should be at least 10 characters<br /></span>
                            </small>
                            <small
                                *ngIf="resetPasswordForm.get('repeatPassword').errors.confirmedValidator && resetPasswordForm.get('repeatPassword').touched"
                                class="error-text text-danger"
                            >
                                <span>The password fields must match.<br /></span>
                            </small>
                        </div>
                    </div>

                    <div class="d-flex mt-4">
                        <input type="submit" value="Update Password" class="btn btn-primary ms-0 mb-4" [disabled]="!resetPasswordForm.valid || loading" />
                    </div>
                </ng-container>

                <p class="my-0 small">
                    <a routerLink="/login"><i class="fa fa-arrow-left text-primary"></i> Back to login</a>
                </p>
            </form>
        </div>
    </div>
</div>
