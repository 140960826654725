import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiErrorResponse, ApiResponse, ApiServiceInterface, UserServiceInterface } from '@hutsix/ngxh6';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { passwordMatchValidator } from '../../../../../../validators/password-match.validator';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public resetPasswordForm: FormGroup;

    public token: string;
    public invalidToken: boolean = false;

    public error?: string;
    public success?: boolean = false;
    public loading = false;

    constructor(
        @Inject('ApiService') private api: ApiServiceInterface,
        @Inject('UserService') private userService: UserServiceInterface,
        private cdRef: ChangeDetectorRef,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
    ) {}

    ngOnInit(): void {
        // Set up validation
        this.resetPasswordForm = this.formBuilder.group(
            {
                password: ['', [Validators.required, Validators.minLength(10)]],
                repeatPassword: ['', [Validators.required]],
            },
            {
                validator: passwordMatchValidator('password', 'repeatPassword'),
            },
        );

        // Get the reset password token, property is also used to show errors if not set
        this.token = this.route.snapshot.params.token;
    }

    public doResetPassword(): void {
        this.loading = true;
        this.api
            .post({ url: '/api/user/update_password', data: { token: this.token, password: this.resetPasswordForm.get('password').value }, skipAuth: true })
            .subscribe(
                (res: ApiResponse<any>) => {
                    this.success = true;
                    this.error = null;
                    this.cdRef.detectChanges();
                },
                (err: ApiErrorResponse<any>) => {
                    this.loading = false;
                    this.error = err.error.error;
                    this.cdRef.detectChanges();
                },
            );
    }
    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
