import { ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { ApiErrorResponse, ApiServiceInterface } from '@hutsix/ngxh6';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { EntrySectionInterface } from '../../../../_generated/api_interfaces/api/entrySection.interface';
import { catchError, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-section-information',
    templateUrl: './section-information.component.html',
    styleUrls: ['./section-information.component.scss'],
})
export class SectionInformationComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public section: ReturnType<EntrySectionInterface['getSectionFromNbr']>;
    public section$: Observable<ReturnType<EntrySectionInterface['getSectionFromNbr']>>;
    public error: ApiErrorResponse<any>;
    public wait: boolean = false;
    private params: Params;
    @Output() hideNavbar = new EventEmitter<boolean>();

    constructor(@Inject('ApiService') public api: ApiServiceInterface, private route: ActivatedRoute, private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.hideNavbar.emit(true);
        this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
            this.params = params;
            this.refreshData();
        });
    }

    refreshData(): void {
        this.section$ = this.api.get({ url: '/api/v1/entry_section/byNbr/' + this.params.nbr, displayErrors: false }).pipe(
            map(res => {
                this.section = res;
                return res;
            }),
            catchError(err => {
                this.error = err;
                this.cdRef.detectChanges();
                return throwError(err);
            }),
        );
    }
    ngOnDestroy = (): void => {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.hideNavbar.emit(false);
    };
}
